import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

const YourEmail = ({ borderRadius }) => {
  const roundedStyle = {
    borderRadius: borderRadius,
  };

  return (
    <div className="your-email py-md-5 py-4" style={roundedStyle}>
      <h1 className="text-center my-5" data-aos="zoom-in">Let's bring your ideas to life</h1>
      <div className="email-wrapper d-flex flex-lg-row flex-md-row flex-sm-row flex-column align-items-center justify-content-center">
        <input
          type="email"
          placeholder="Your email address"
          className="me-lg-4 me-md-4 me-sm-4 me-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3 text-md-start text-center"
        />
        <Button btnTxt="Start Now" bgColor="#292929" />
      </div>
    </div>
  );
};

YourEmail.propTypes = {
  borderRadius: PropTypes.string.isRequired,
};

export default YourEmail;
