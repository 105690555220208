import { useState } from 'react';
import axiosInstance from './axiosInstance';

const useAxios = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const axiosRequest = async (url, method, data = null) => {
    setIsLoading(true);
    try {
      const axiosMethod = {
        get: axiosInstance.get,
        post: axiosInstance.post,
        put: axiosInstance.put,
        delete: axiosInstance.delete,
      }[method];

      if (!axiosMethod) {
        throw new Error(`Invalid method '${method}'`);
      }

      const res = await axiosMethod(url, data);


      setResponse(res);
      return res;
    } catch (error) {
      console.log("error",error)
    }
  };

  return { axiosRequest, isLoading, response };
};

export default useAxios;
