import React from 'react'
import logoPath from "./../assets/images/logoBlack.svg"
const Footer = () => {
  return (
    <div className="footerStyle">
      <footer class="text-center text-lg-start bg-body-tertiary text-muted">
        <section class="">
          <div class="container text-start text-md-start mt-5">
            <div class="row mt-3 align-items-center">
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold mb-4">
                  <a href="/" class="navbar-brand">
                    <img src={logoPath} alt="Logo" />
                  </a>
                </h6>
                <p className='text-muted mb-3'>
                  Social media validation business model canvas graphical user interface
                  launch party creative facebook iPad twitter.
                </p>

                <p className='text-muted mb-3'>
                  All rights reserved.
                </p>
              </div>

              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 col-6">
                <h6 class="fw-bold mb-4 text-dark">Landings</h6>
                <div class="mb-3">
                  <a href="/" class="text-muted text-decoration-none">Home</a>
                </div>
                <div class="mb-3">
                  <a href="/products" class="text-muted text-decoration-none">Products</a>
                </div>
                <div class="mb-3">
                  <a href="/services" class="text-muted text-decoration-none">Services</a>
                </div>
              </div>

              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 col-6">
                <h6 class="fw-bold mb-4 text-dark">Company</h6>
                <div class="mb-3">
                  <a href="/" class="text-muted text-decoration-none">Home</a>
                </div>
                <div class="mb-3">
                  <a href="/apply" class="text-muted text-decoration-none">Careers<span class="badge rounded-pill bg-warning text-dark px-2">Hiring!</span></a>
                </div>
                <div class="mb-3">
                  <a href="/services" class="text-muted text-decoration-none">Services</a>
                </div>
              </div>

              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 class="fw-bold mb-4 text-dark">Resources</h6>
                <div class="mb-3">
                  <a href="/blog" class="text-muted text-decoration-none">Blog</a>
                </div>
                <div class="mb-3">
                  <a href="/products" class="text-muted text-decoration-none">Products</a>
                </div>
                <div class="mb-3">
                  <a href="/services" class="text-muted text-decoration-none">Services</a>
                </div>
              </div>


            </div>
          </div>
        </section>
      </footer>
    </div>
  )
}

export default Footer