import React from "react";

import ExploreLink from "./ExploreLink";

import { LuDot } from "react-icons/lu";

const TextWithImage = ({
  img,
  title,
  description,
  link,
  reverseContentOrder,
  frameworkText,
  frameworks,
  smallCard,
  descTextColor,
  titleTextColor,
  linkColor,
  linkLineColor,
  subServiceBgColor
}) => {

  const dotColors = ["#0DBBFC", "#69E6A6", "#C4C4C4"];

  return (
    <div  style={{ backgroundColor: subServiceBgColor }}>
    <div className="txtWimgWrapper container">
      <div
        className={`row d-flex align-items-center justify-content-between ${
          reverseContentOrder ? "flex-row-reverse" : ""
        }`}
      >
        <div
          className={`col-lg-7 col-md-6 col-12 d-flex flex-column text-md-start text-center ${
            reverseContentOrder
              ? "text-lg-end text-md-end text-start"
              : "text-lg-start text-md-start text-start"
          }`}
        >
          <h2 data-aos="zoom-in" style={{ color: titleTextColor }}>{title}</h2>
          <p className="my-3" style={{ color: descTextColor }}>
            {description}
          </p>
          <div
            className={`fw-wrapper row align-items-center${
              reverseContentOrder
                ? "ms-lg-auto mx-lg-0 mx-md-0 mx-auto"
                : "me-lg-auto mx-lg-0 mx-md-0 mx-auto"
            } mt-3 mb-4`}
            // data-aos="zoom-out"
          >
            <div className="col-md-6 col-12 justify-content-center">
            <h6 className="me-2 mb-0">{frameworkText}</h6>
            </div>
           <div className="col-md-6 col-12 d-flex align-items-center justify-content-md-start justify-content-center">
           <div className="fw-container d-flex align-items-center justify-content-md-start justify-content-center mt-md-0 mt-2">
              {frameworks?.map((framework, index) => (
                <img
                  key={index}
                  src={framework}
                  alt="..."
                  className="img-fluid me-2"
                />
              ))}
            </div>
           </div>
          </div>
          <div
            className={`${
              reverseContentOrder
                ? "ms-lg-auto mx-lg-0 mx-md-0 mx-auto"
                : "me-lg-auto mx-lg-0 mx-md-0 mx-auto"
            }`}
          >
            {/* to={link}
            style={{color:linkColor}} */}
            <ExploreLink to={link} color={linkColor} lineColor={linkLineColor} linkTxt="Explore our work" />
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12 d-flex mt-5 mt-lg-0 mt-md-0">
          <div
            className={`img-container position-relative ${
              reverseContentOrder
                ? "me-lg-auto mx-lg-0 mx-auto"
                : "ms-lg-auto mx-lg-0 mx-auto"
            } `}
          >
            <img src={img} alt="..." className="img-fluid" data-aos="flip-right"/>
            <div className="small-card d-flex flex-column justify-content-center position-absolute start-50 translate-middle-x" data-aos="fade-up">
              {smallCard?.map((item, index) => (
                <div key={index} className="item d-flex align-items-center">
                  <LuDot style={{ color: dotColors[index % dotColors.length] }} />
                  <p className="mb-0">{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TextWithImage;
