import React from 'react'

const Button = ({btnTxt, bgColor}) => {
  return (
    <button className='custom-btn d-flex align-items-center' style={{backgroundColor: bgColor}}>
        {btnTxt}
    </button>
  )
}

export default Button