import React from "react";
import YourEmail from "../components/YourEmail";
import TextWithImage from "../components/TextWithImage";
import image1 from "./../assets/images/aboutUsFirstImg.png";
import logo1 from "../assets/images/framework-logo1.svg";
import logo2 from "../assets/images/framework-logo2.svg";
import logo3 from "../assets/images/framework-logo3.svg";
import PartWithImgAside from "../components/PartWithImgAside";
import bgImage from "./../assets/images/bgSoftwareDevelopment.svg";
import "./../style/harrisia/variables.scss";

const SoftwareDevelopment = () => {
  return (
    <>
      <div className="softwareDevelopment">
        <PartWithImgAside
          bgColor="#292929"
          color="white"
          title="Software Development"
          imageSrc={bgImage}
          subtitle="Services"
          contentPosition="justify-content-center"
          textPosition="text-center"
        />
        <TextWithImage
          img={image1}
          title="Application Support & Continuous Improvement:"
          description="Unlock the full potential of online retail with our E-Commerce
         solutions. We specialize in creating user-friendly, visually
         appealing, and secure online stores that attract customers and
         boost sales. Whether you are launching a new E-Commerce platform
         or seeking to revamp an existing one, our experts are here to
         guide you towards E-Commerce success."
          titleTextColor="#FFFFFF"
          descTextColor="#FFFFFF"
          linkColor="#FFFFFF"
          linkLineColor="#fff"
          link="/"
          reverseContentOrder={false}
          frameworkText="Mobile App Frameworks"
          frameworks={[logo1, logo2, logo3]}
          smallCard={[
            { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
            { color: "#69E6A6", percentage: "25%", text: "Communication" },
            { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
          ]}
        />
        <TextWithImage
          img={image1}
          title="Application Support & Continuous Improvement:"
          description="Unlock the full potential of online retail with our E-Commerce
         solutions. We specialize in creating user-friendly, visually
         appealing, and secure online stores that attract customers and
         boost sales. Whether you are launching a new E-Commerce platform
         or seeking to revamp an existing one, our experts are here to
         guide you towards E-Commerce success."
          link="/"
          linkLineColor="#fff"
          linkColor="#FFFFFF"
          titleTextColor="#FFFFFF"
          descTextColor="#FFFFFF"
          reverseContentOrder={true}
          frameworkText="Mobile App Frameworks"
          frameworks={[logo1, logo2, logo3]}
          smallCard={[
            { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
            { color: "#69E6A6", percentage: "25%", text: "Communication" },
            { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
          ]}
        />
        <TextWithImage
          img={image1}
          title="Application Support & Continuous Improvement:"
          description="Unlock the full potential of online retail with our E-Commerce
         solutions. We specialize in creating user-friendly, visually
         appealing, and secure online stores that attract customers and
         boost sales. Whether you are launching a new E-Commerce platform
         or seeking to revamp an existing one, our experts are here to
         guide you towards E-Commerce success."
          link="/"
          linkLineColor="#fff"
          linkColor="#FFFFFF"
          titleTextColor="#FFFFFF"
          descTextColor="#FFFFFF"
          reverseContentOrder={false}
          frameworkText="Mobile App Frameworks"
          frameworks={[logo1, logo2, logo3]}
          smallCard={[
            { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
            { color: "#69E6A6", percentage: "25%", text: "Communication" },
            { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
          ]}
        />

        <YourEmail />
      </div>
    </>
  );
};

export default SoftwareDevelopment;
