import React, { useEffect, useState } from "react";
import YourEmail from "../components/YourEmail";
import TextWithImage from "../components/TextWithImage";
import PartWithImgAside from "../components/PartWithImgAside";
import useAxios from "../../src/utils/useAxios";
import { useParams } from "react-router-dom";
import { useColorTheme } from './../pages/ColorThemeContext';

const SingleService = () => {
  const { id } = useParams();
  const { axiosRequest } = useAxios();
  const [service, setService] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const { setColorTheme } = useColorTheme();

  const getServicesData = async () => {
    try {
      const response = await axiosRequest(`/api/services/${id}`, "get");
      setService(response?.data);
      setSubServices(response?.data?.sub_services);
      setColorTheme(response?.data?.bg_color || '#FFFFFF');
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
console.log("Service",service?.bg_color)
  useEffect(() => {
    getServicesData();
  }, [id]);

  return (
    <div className="softwareDevelopment">
      <PartWithImgAside
        bgColor={service?.bg_color}
        color={service?.color}
        title={service?.title}
        imageSrc={`${process.env.REACT_APP_UPLOADS}/${service?.services_img_url}`}
        subtitle="Services"
        contentPosition="justify-content-center"
        textPosition="text-center"
      />
      {subServices.map((subService, index) => (
        <div key={index}>
          <TextWithImage
            img={`${process.env.REACT_APP_UPLOADS}/${subService?.img_url}`}
            subServiceBgColor={service?.bg_color}
            title={subService?.title}
            description={subService?.content}
            titleTextColor={service?.color}
            descTextColor={service?.color}
            linkColor={service?.link_color}
            linkLineColor={service?.link_line_color}
            link={subService?.page_url}
            reverseContentOrder={index % 2 === 0 ? false : true}
            frameworkText="Mobile App Frameworks"
            frameworks={subService?.sub_services_frameworks?.map(framework => `${process.env.REACT_APP_UPLOADS}/${framework.img_url}`)}
            smallCard={subService?.sub_services_percentages?.map(item => (item?.percentage))}
          />
        </div>
      ))}
      <YourEmail />
    </div>
  );
};

export default SingleService;
