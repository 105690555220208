import React from 'react'
import Footer from '../components/Footer'
import image1 from "./../assets/images/contactUsImg.svg"
import Button from "../components/Button";
const GetInTouch = () => {
  return (
    <>
      <div className="getInTouch text-center">
        <div className="getInTouch-bgpart1"></div>
        <div className="getInTouch-part1">
          <div className="container">
            <div className="py-3">
              <p className='fw-lighter' data-aos="fade-left">Contact us</p>
              <h1 className="fw-lighter" data-aos="zoom-in">We’re here to help</h1>
              <p className='my-4 fw-lighter' data-aos="fade-right">and we’d love to know more</p>
            </div>
            <div className="card-part">
              <div className="row justify-content-center">
              <div className="col-md-8 col-11 col-lg-6 p-2" data-aos="flip-left">
                <div class="card-getInTouch" style={{ backgroundImage: `url(${image1})` }}></div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="getInTouch-part2 text-center">
          <h1 className='my-4' data-aos="fade-left">Get in touch</h1>
          <div className="d-flex justify-content-center">
            <div className="col-md-7 col-lg-5 col-12 px-2">
              <form>
                <div class="mb-3">
                  <div className="text-start">
                    <label for="exampleFormControlInput1 text-start" class="form-label" data-aos="fade-right">Full name</label>
                  </div>
                  <input type="text" class="form-control inputStyle" id="exampleFormControlInput1" placeholder="Jane Doe" />
                </div>
                <div class="mb-3">
                  <div className="text-start">
                    <label for="exampleFormControlInput1" class="form-label" data-aos="fade-right">Email address</label>
                  </div>
                  <input type="email" class="form-control inputStyle" id="exampleFormControlInput1" placeholder="someone@gmail.com" />
                </div>
                <div class="mb-3">
                  <div className="text-start">
                    <label for="exampleFormControlInput1 text-start" class="form-label" data-aos="fade-right">Subject</label>
                  </div>
                  <input type="text" class="form-control inputStyle" id="exampleFormControlInput1" placeholder="I’d like a quote for Software Development" />
                </div>
                <div class="mb-3">
                  <div className="text-start">
                    <label for="exampleFormControlTextarea1" class="form-label" data-aos="fade-right">Body</label>
                  </div>
                  <textarea class="form-control inputStyle" id="exampleFormControlTextarea1" rows="7"></textarea>
                </div>
              <div className="d-flex justify-content-center my-5" data-aos="zoom-in">
                <Button btnTxt="Submit" bgColor="#000" />
              </div>
              </form>
            </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default GetInTouch