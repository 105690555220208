
export const RoutesWithLoader = {
    // pagess
    FrontPage: { path: "/" },
    SingleWork: {path: "/:id"},
    About: { path: "/about" },
    Services: { path: "/services" },
    SingleService: { path: "/services/:id" },
    SoftwareDevelopment: { path: "/softwareDevelopment" },
    BusinessSolutions: { path: "/businessSolutions" },
    SupportAndConsulting: { path: "/supportAndConsulting" },
    Products: { path: "/products" },
    GetInTouch: { path: "/getInTouch" },
    Technology: { path: "/technology" },
    ComplexProducts: { path: "/otherProducts" },
    Apply: { path: "/apply" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};