import React, { useEffect, useState } from "react";
import YourEmail from "../components/YourEmail";

import banner_img from "../assets/images/products-banner-img.svg";
import feather from "../assets/images/nodejs.png";
import Carousel from "react-multi-carousel";
import useAxios from "../utils/useAxios";
import PartWithImgAside from "../components/PartWithImgAside";
import bgImage from "./../assets/images/bgSoftwareDevelopment.svg";
import "./../style/harrisia/variables.scss";
const ComplexProducts = () => {
    const [products, setProducts] = useState([]);
    const { axiosRequest } = useAxios();
    const getData = async () => {
        try {
            const response = await axiosRequest("/api/complex_products/all", "get");
            setProducts(response?.data);
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };
    console.log("products", products)

    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            {/* <PartWithImgAside
                bgColor="#292929"
                color="white"
                title="Navigating Complexity"
                imageSrc={bgImage}
                subtitle="Advanced Solutions for Critical Problems"
                contentPosition="justify-content-center"
                textPosition="text-center"
            /> */}
            <div class="services p-4">
                <div class="container">
                    <h2 className="text-center title">Navigating Complexity</h2>
                    {products?.map((product, index) => (
                        <div key={index} >
                            <div className="row justify-content-center text-start mt-5">
                                <div className="col-md-11 col-12">

                                    <p dangerouslySetInnerHTML={{ __html: product?.content }}></p>
                                </div>
                            </div>
                            <div class="row text-center">
                            {product?.complex_product_images?.map((complexProduct, index) => {
                                return (
                                        <div class="col-md-4">
                                            <div class="square">  <img
                                                src={`${process.env.REACT_APP_UPLOADS}/${complexProduct?.img_url}`}
                                                alt={complexProduct?.title}
                                                className="img-fluid"
                                                st
                                            /></div>
                                            <div class="serv">
                                                <h6>{complexProduct?.title}</h6>
                                                <p>{complexProduct?.content}</p>
                                            </div>
                                        </div>

)
}
)}
</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ComplexProducts;
