import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useAxios from '../../src/utils/useAxios';

const ColorThemeContext = createContext();

const blackColor = "#292929";
const greyColor = "#E5E5E5";
const whiteColor = "#fff";
const anotherColor = "#FAA700";

const determineInitialColorTheme = (pathname) => {
  if (pathname === '/about' || pathname === '/getIntouch') {
    return blackColor;
  }
  if (pathname === '/apply' ) {
    return greyColor;
  }
  if (pathname === '/otherProducts' ) {
    return whiteColor;
  }
  if (pathname === '/services') {
    return blackColor;
  }
  return blackColor; // default color if no match
};

export const ColorThemeProvider = ({ children }) => {
  const { axiosRequest } = useAxios();
  const location = useLocation();
  const { id } = useParams();
  const [colorTheme, setColorTheme] = useState(determineInitialColorTheme(location.pathname));

  useEffect(() => {
    const fetchColorTheme = async () => {
      if (id && location.pathname.startsWith('/services/')) {
        try {
          const response = await axiosRequest(`/api/services/${id}`, 'get');
          const data = response?.data;

          if (data) {
            setColorTheme(data.color || anotherColor);
          }
        } catch (error) {
          console.error('Error fetching service data:', error);
        }
      } else {
        setColorTheme(determineInitialColorTheme(location.pathname));
      }
    };

    fetchColorTheme();
  }, [location.pathname, id]);

  return (
    <ColorThemeContext.Provider value={{ colorTheme, setColorTheme }}>
      {children}
    </ColorThemeContext.Provider>
  );
};

export const useColorTheme = () => useContext(ColorThemeContext);
