import React from 'react';
import { useColorTheme } from './../pages/ColorThemeContext';
import blackLogoPath from './../assets/images/logoBlack.svg';
import whiteLogoPath from './../assets/images/logo.svg';

const Navbar = () => {
  const { colorTheme } = useColorTheme();

  const colorThemes = {
    '#E5E5E5': { logo: blackLogoPath, color: 'black' },
    '#292929': { logo: whiteLogoPath, color: 'white' },
    '#FAA700': { logo: blackLogoPath, color: 'white' },
  };

  const logoPath = colorThemes[colorTheme]?.logo || blackLogoPath;
  const backgroundColor = colorTheme;
  const color = colorThemes[colorTheme]?.color || 'black';

  return (
    <div className="navbar-part" style={{ backgroundColor }}>
      <nav className="navbar navbar-expand-lg p-2" style={{ color, backgroundColor }}>
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img src={logoPath} alt="Logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link mx-2 cursor-pointer" style={{ color, cursor: 'pointer' }} href="/about">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2 cursor-pointer" style={{ color, cursor: 'pointer' }} href="/services">Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2 cursor-pointer" style={{ color, cursor: 'pointer' }} href="/technology">Technology</a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2 cursor-pointer" style={{ color, cursor: 'pointer' }} href="/products">Products</a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2 cursor-pointer" style={{ color, cursor: 'pointer' }} href="/getIntouch">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
