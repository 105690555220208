import React, { useEffect, useState } from "react";
import YourEmail from "../components/YourEmail";
import useAxios from "../utils/useAxios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useColorTheme } from "../pages/ColorThemeContext";

import { GoMail } from "react-icons/go";
import { MdOutlineLocalPhone } from "react-icons/md";
import { BsGlobe } from "react-icons/bs";

const SingleWork = () => {
  const { id } = useParams();
  const { axiosRequest } = useAxios();
  const [ourWork, setOurWork] = useState([]);
  const [subOurWork, setSubOurWork] = useState([]);
  const { setColorTheme } = useColorTheme();

  const getWorkData = async () => {
    try {
      const response = await axiosRequest(`api/ourwork/${id}`, "get");
      setOurWork(response?.data);
      setSubOurWork(response?.data?.sub_ourwork);
      setColorTheme(response?.data?.sub_ourwork?.color || "#292929");
    } catch (error) {
      console.error("Error fetching work", error);
    }
  };

  useEffect(() => {
    getWorkData();
  }, [id]);
  

  const links = subOurWork?.links?.split(",").map((link) => link.trim()) || [];
  const linkText =
    subOurWork?.link_txt?.split(",").map((linkT) => linkT.trim()) || [];

  return (
    <div className="single-work">
      <div className="banner" style={{ backgroundColor: subOurWork?.color }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12 text-md-start text-center">
              <h1 className="fw-bold" data-aos="fade-right">
                {ourWork?.title}
              </h1>
              <p>{ourWork?.content}</p>
            </div>
            <div className="col-md-6 col-12">
              <img
                src={`${process.env.REACT_APP_UPLOADS}/${subOurWork?.img_url}`}
                alt="..."
                className="img-fluid"
                data-aos="flip-left"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="single-work-content my-md-5 my-3">
        <div className="container">
          <div className="row">
            <p dangerouslySetInnerHTML={{ __html: subOurWork.content }}></p>

            <div>
              {links.length > 0 && (
                <div className="mb-md-4 mb-3">
                  <p className="fw-bold m-0">{linkText[0] || "Live Env"}</p>
                  <Link to={links[0]} target="_blank" rel="noopener noreferrer">
                    {links[0]}
                  </Link>
                </div>
              )}
              {links.length > 1 && (
                <div className="mb-md-4 mb-3">
                  <p className="fw-bold m-0">{linkText[1] || "Prod Env"}</p>
                  <Link to={links[1]} target="_blank" rel="noopener noreferrer">
                    {links[1]}
                  </Link>
                </div>
              )}
            </div>

            <div className="info row justify-content-md-between justify-content-center my-md-4 my-3">
              <div className="col-md-4 col-sm-6 col-12 d-flex align-items-center justify-content-center">
                <GoMail
                  className="me-2 my-md-0 my-2"
                  style={{ color: subOurWork?.color }}
                />
                <span>info@harrisia.com</span>
              </div>
              <div className="col-md-4 col-sm-6 col-12 d-flex align-items-center justify-content-center">
                <MdOutlineLocalPhone
                  className="me-2 my-md-0 my-2"
                  style={{ color: subOurWork?.color }}
                />
                <span>+383 38 600 488</span>
              </div>
              <div className="col-md-4 col-sm-6 col-12 d-flex align-items-center justify-content-center">
                <BsGlobe
                  className="me-2 my-md-0 my-2"
                  style={{ color: subOurWork?.color }}
                />
                <span>https://www.harrisia.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <YourEmail />
    </div>
  );
};

export default SingleWork;
