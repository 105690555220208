import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import YourEmail from "../components/YourEmail";

import img1 from "../assets/images/services-banner-img1.svg";
import img2 from "../assets/images/services-banner-img2.svg";
import img3 from "../assets/images/services-banner-img3.svg";
import PartWithImgAside from "../components/PartWithImgAside";

import useAxios from "../../src/utils/useAxios";

const Services = () => {
  const { axiosRequest } = useAxios();

  const [services, setServices] = useState([]);


  const getServicesData = async () => {
    try {
      const response = await axiosRequest("/api/services/all", "get");
      setServices(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    getServicesData();
  }, []);

  console.log("serviset", services);
  return (
    <div className="services-page">
      {/* Banner */}
      <div className="banner p-lg-5 p-md-4 p-sm-3 p-2">
        <div className="container">
          <div className="text-center mb-md-5 mb-3">
            <p className="fw-lighter pt-3" data-aos="fade-left">
              Services
            </p>
            <h1 data-aos="fade-right">All we can do for you & more.</h1>
          </div>

          <div className="row align-items-center justify-content-center mx-auto">
            <div className="col-lg-4 col-md-6 col-12 mt-4 text-center">
              <img
                src={img1}
                alt="..."
                className="img-fluid"
                data-aos="fade-down"
              />
            </div>

            <div className="col-lg-4 col-md-6 col-12 mt-4 text-center">
              <img
                src={img2}
                alt="..."
                className="img-fluid"
                data-aos="fade-up"
              />
            </div>

            <div className="col-lg-4 col-md-6 col-12 mt-4 text-center">
              <img
                src={img3}
                alt="..."
                className="img-fluid"
                data-aos="fade-down"
              />
            </div>
          </div>
        </div>
      </div>

      {services.map((service, index) => (
        <PartWithImgAside
          key={index}
          height={service.height}
          heightSm={service.height_sm}
          bgColor={service.bg_color}
          color={service.color}
          title={service.title}
          content={service.content}
          imageSrc={`${process.env.REACT_APP_UPLOADS}/${service.services_img_url}`}
          contentPosition="justify-content-center"
          textPosition="text-md-start text-center"
          containerType="container"
          exploreLink={true}
          to={service.page_url + service?.id}
          linkTxt={service.link_txt}
          linkColor={service.link_color}
          linkLineColor={service.link_line_color}
          customButtons={(service.sub_services || []).map(
            (subService, subIndex) => ({
              text: subService.title,
              color: subService.color,
              borderColor: subService.border_color,
            })
          )}
        />
      ))}

      {/* Software Development */}
      {/* <PartWithImgAside
        height="75vh"
        heightSm="100vh"
        bgColor="#292929"
        color="white"
        title="Software Development"
        content="Whether you need a custom web application, a feature-rich mobile
          app, or a robust desktop software, we have the expertise to
          bring your ideas to life. Our agile development approach ensures
          efficient project management and on-time delivery, while our
          focus on user experience guarantees that your end-users will
          love the final product. Let's collaborate and take your software
          ideas to new heights!"
        imageSrc={sd_img}
        contentPosition="justify-content-center"
        textPosition="text-start"
        containerType="container"
        exploreLink={true}
        to="/softwareDevelopment"
        linkTxt="Explore page"
        linkColor="#fff"
        linkLineColor="#fff"
        customButtons={[
          {
            text: "Mobile Application Deveopment",
            color: "#fff",
            borderColor: "#fff",
          },
          { text: "Software Customization", color: "fff", borderColor: "#fff" },
        ]}
      /> */}

      {/* <PartWithImgAside
        height="80vh"
        heightSm="120vh"
        bgColor="#FAA700"
        color="#000"
        title="Business Solutions"
        content="From process optimization to implementing cutting-edge
        technologies, we offer a holistic approach to take your business
        to the next level. With a track record of delivering tangible
        results, we are your trusted partners on the journey towards
        sustained excellence. Experience the power of personalized
        Business Solutions - contact us today!"
        imageSrc={bs_img}
        contentPosition="justify-content-center"
        textPosition="text-start"
        containerType="container"
        exploreLink={true}
        to="/businessSolutions"
        linkTxt="Explore page"
        linkColor="#000"
        linkLineColor="#000"
        customButtons={[
          { text: "E-Commerce", color: "#000", borderColor: "#000" },
          {
            text: "The Proof-of-Concept Design / MVP Development",
            color: "#000",
            borderColor: "#000",
          },
          {
            text: "Legacy Application Migration & Enhancment",
            color: "#000",
            borderColor: "#000",
          },
          {
            text: "Application Integration & Customization",
            color: "#000",
            borderColor: "#000",
          },
        ]}
      /> */}

      {/* Support & Consulting */}
      {/* <PartWithImgAside
        height="100vh"
        heightSm="160vh"
        bgColor="#E5E5E5"
        color="#000"
        title="Support & Consulting"
        content="We offer ongoing support to keep your website running smoothly
        and securely, ensuring a seamless user experience. Our
        consultants will analyze your website's performance and provide
        actionable insights to enhance its impact on your audience.
        Maximize your website's efficiency and stay ahead of the
        competition with our Support & Consulting services!"
        imageSrc={sc_img}
        contentPosition="justify-content-center"
        textPosition="text-start"
        containerType="container"
        exploreLink={true}
        to="/supportAndConsulting"
        linkTxt="Explore page"
        linkColor="#000"
        linkLineColor="#000"
        customButtons={[
          {
            text: "Application Support & Continuous Improvement",
            color: "#000",
            borderColor: "#000",
          },
          { text: "Testing & QA", color: "#000", borderColor: "#000" },
          {
            text: "Project Management / Scrum",
            color: "#000",
            borderColor: "#000",
          },
          {
            text: "Technical and Software Consultancy",
            color: "#000",
            borderColor: "#000",
          },
          {
            text: "Code Review and Progress Reporting",
            color: "#000",
            borderColor: "#000",
          },
          { text: "Outsourcing", color: "#000", borderColor: "#000" },
          {
            text: "Deployment, Maintenance, and Support",
            color: "#000",
            borderColor: "#000",
          },
          { text: "UX/UI", color: "#000", borderColor: "#000" },
        ]}
      /> */}

      <div>
        <YourEmail />
      </div>
    </div>
  );
};

export default Services;
