import React, { useState } from 'react'
import image1 from "./../assets/images/aboutUsFirstImg.png"
import image3 from "./../assets/images/applyImg.svg"
import image2 from "./../assets/images/photoCard.png"
import Button from "../components/Button";
const Apply = () => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName('');
    }
  };
  return (
    <div className="apply text-center">
      <div className="apply-part1 text-center container">
        <p className='pt-3' data-aos="fade-right">Careers</p>
        <h1 className='my-5 fw-normal' data-aos="fade-left">We’re constantly hiring. </h1>
        <div className="d-flex justify-content-center">
          <div className="col-md-11 col-10 col-lg-9">
            <div className="row">
              <div className="col-md-4 col-12 col-lg-3 p-2">
                <div class="card card-apply" data-aos="fade-right" style={{ backgroundImage: `url(${image1})` }}></div>
              </div>
              <div className="col-md-4 col-12 col-lg-5 p-2">
                <div class="card card-apply" data-aos="zoom-out" style={{ backgroundImage: `url(${image3})` }}></div>
              </div>
              <div className="col-md-4 col-12 col-lg-3 p-2">
                <div class="card card-apply" data-aos="fade-left" style={{ backgroundImage: `url(${image1})` }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="apply-part2 text-center">
        <h1 className='my-4' data-aos="fade-left">Apply</h1>
        <div className="d-flex justify-content-center">
          <div className="col-md-7 col-lg-5 col-10 px-2">
            <form>
              <div class="mb-3">
                <div className="text-start">
                  <label for="exampleFormControlInput1 text-start" class="form-label" data-aos="fade-right">Full name</label>
                </div>
                <input type="text" class="form-control inputStyle" id="exampleFormControlInput1" placeholder="Jane Doe" />
              </div>
              <div class="mb-3">
                <div className="text-start">
                  <label for="exampleFormControlInput1" class="form-label" data-aos="fade-right">Email address</label>
                </div>
                <input type="email" class="form-control inputStyle" id="exampleFormControlInput1" placeholder="someone@gmail.com" />
              </div>
              <div class="mb-3">
                <div className="text-start">
                  <label for="exampleFormControlInput1 text-start" class="form-label" data-aos="fade-right">Role</label>
                </div>
                <input type="text" class="form-control inputStyle" id="exampleFormControlInput1" placeholder="I’d like a quote for Software Development" />
              </div>
              <div class="mb-3">
                <div className="text-start">
                  <label for="exampleFormControlTextarea1" class="form-label" data-aos="fade-right">Motivation</label>
                </div>
                <textarea class="form-control inputStyle" id="exampleFormControlTextarea1" rows="7" placeholder='Tell us why you’d like to work with us.'></textarea>
              </div>
              <div className="mb-3 text-md-start text-center">
                <div className="">
                  <label htmlFor="fileInput" className="form-label" data-aos="fade-right">CV</label>
                </div>
                <label htmlFor="fileInput" className="btn-upload" data-aos="zoom-in">
                  {fileName ? fileName : 'Upload CV'}
                  <input
                    id="fileInput"
                    name="userfile"
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    onChange={handleFileChange}
                  />
                </label>
              </div>

              <div className="d-flex justify-content-center my-5" data-aos="zoom-out">
                <Button btnTxt="Submit" bgColor="#000" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Apply