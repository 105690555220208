import React, { useEffect, useState } from "react";
import YourEmail from "../components/YourEmail";

import banner_img from "../assets/images/banner_bg.svg";
import feather from "../assets/images/nodejs.png";
import Carousel from "react-multi-carousel";
import useAxios from "../utils/useAxios";

const Technology = () => {
    const [products, setProducts] = useState([]);
    const { axiosRequest } = useAxios();
    const getData = async () => {
        try {
            const response = await axiosRequest("/api/technology/all", "get");
            setProducts(response?.data);
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="technology-page">
            {/* Products Banner */}

            <div className="banner">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className=" col-12 text-center py-5 text-light">
                            <p className="fw-lighter pt-5">Technology stack</p>
                            <h1>Your trusted partner for software innovation and transformation</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-3 my-md-4 my-lg-5">
                {products?.map((product, index) => {
                    const isOddIndex = index % 2 === 0;
                    return (
                        <div key={index} className="row align-items-center justify-content-between py-md-5 py-3 ">
                            {isOddIndex ? (
                                <>
                                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-2 mt-5 order-1 order-md-1 text-lg-start text-md-start text-center">
                                        <h2 className="mb-md-4 my-3">{product?.name}</h2>
                                        <div class="row mt-3">
                                            {product?.sub_technologies?.map((sub_Tech, index) => (

                                                <div class="col-lg-3 col-md-4 col-6 mt-3">
                                                    <div class="techCard pt-4 pb-2 text-center" data-aos="fade-left"
                                                        data-aos-easing="ease-in-sine">
                                                        <div>
                                                            <img src={`${process.env.REACT_APP_UPLOADS}/${sub_Tech?.img_url}`} alt={sub_Tech?.name} class="img-fluid pt-3" />
                                                            <h6 class="text-center animatedText mt-0 fw-bold mb-4 ">{sub_Tech?.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center mt-lg-0 mt-2 mt-5 order-2 order-md-2 justify-content-md-end justify-content-center" data-aos="fade-right"
                                        data-aos-easing="ease-in-sine">
                                        <img src={`${process.env.REACT_APP_UPLOADS}/${product?.img_url}`} alt="..." className="me-3 img-fluid" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-2 mt-5 order-1 order-md-2 text-lg-start text-md-start text-center">
                                        <h2 className="mb-md-4 mb-3">{product?.name}</h2>
                                        <div class="row mt-3">
                                            {product?.sub_technologies?.map((sub_Tech, index) => (

                                                <div class="col-lg-3 col-md-4 col-6 mt-3">
                                                    <div class="techCard pt-4 pb-2 text-center" data-aos="fade-right"
                                                        data-aos-easing="ease-in-sine">
                                                        <div>
                                                            <img src={`${process.env.REACT_APP_UPLOADS}/${sub_Tech?.img_url}`} alt={sub_Tech?.name} class="img-fluid pt-3" />
                                                            <h6 class="text-center animatedText mt-0 fw-bold mb-4 ">{sub_Tech?.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-6 col-12 d-flex align-items-center mt-lg-0 mt-2 mt-5 order-2 order-md-1 justify-content-md-start justify-content-center" data-aos="fade-left"
                                        data-aos-easing="ease-in-sine">
                                        <img src={`${process.env.REACT_APP_UPLOADS}/${product?.img_url}`} alt="..." className="me-3 img-fluid" />
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
            <div>
                <YourEmail />
            </div>
        </div>
    );
};

export default Technology;
