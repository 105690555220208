import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";
import banner_img from "../assets/images/frontpage-banner.png";

import img4 from "../assets/images/frontPage-lt-img.png";

import img5 from "../assets/images/frontPage-cs-img.svg";

import logo1 from "../assets/images/vite-logo.svg";
import logo2 from "../assets/images/lucide-logo.svg";
import logo3 from "../assets/images/mui-logo.svg";
import logo4 from "../assets/images/react-logo.svg";

import Button from "../components/Button";
import YourEmail from "../components/YourEmail";

import { FiFeather, FiEye, FiSun } from "react-icons/fi";
import { LuDot } from "react-icons/lu";
import { FaCircleCheck } from "react-icons/fa6";
import ExploreLink from "../components/ExploreLink";
import CustomButton from "../components/CustomButton";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { HiArrowSmallLeft, HiArrowSmallRight } from "react-icons/hi2";

import moment from "moment";

import useAxios from "../../src/utils/useAxios";

const data = {
  labels: ["Agile Development", "Communication", "Maintenance"],
  datasets: [
    {
      data: [45, 25, 25],
      backgroundColor: ["#0DBBFC", "#69E6A6", "#C4C4C4"],
      borderWidth: 1,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  cutout: "70%",
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsiveT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 769, min: 0 },
    items: 1,
  },
};

const responsiveR = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 769, min: 0 },
    items: 1,
  },
};

const defaultImg =
  "https://img.freepik.com/free-photo/image-icon-front-side-with-white-background_187299-39874.jpg?t=st=1717275902~exp=1717279502~hmac=775d8f2ca9aa31483e1c8a3f7d2ec41572e306abdbb6712c47e98948225c2c7c&w=740";

const FrontPage = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [ourWork, setOurWork] = useState([]);
  const [subOurWork, setSubOurWork] = useState([]);
  const [ourBlog, setOurBlog] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [trustedBy, setTrustedBy] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleAfterChange = (previousSlide, { currentSlide }) => {
    setCurrentSlide(currentSlide);
  };

  const isActive = (index) => {
    const visibleItems = 3;
    const middleIndex = Math.floor(visibleItems / 2);
    return index === (currentSlide + middleIndex) % testimonials?.length;
  };

  const initialBlogsToShow = 3;
  const maxBlogsToShow = 6;
  const [blogsToShow, setBlogsToShow] = useState(initialBlogsToShow);

  const handleLoadMore = () => {
    setBlogsToShow((prev) =>
      Math.min(prev + initialBlogsToShow, maxBlogsToShow)
    );
  };

  const handleLoadLess = () => {
    setBlogsToShow(initialBlogsToShow);
  };

  const { axiosRequest } = useAxios();

  const getWorkData = async () => {
    try {
      const response = await axiosRequest("/api/ourwork/all", "get");
      setOurWork(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getSubWorkData = async (ourwork_id) => {
    try {
      const response = await axiosRequest(`/api/subourwork/${ourwork_id}`);
      setSubOurWork((prevState) => ({
        ...prevState,
        [ourwork_id]: response?.data,
      }));
    } catch (error) {
      console.error("Error fetching sub our work", error);
    }
  };

  const getBlogData = async () => {
    try {
      const response = await axiosRequest("/api/blogs/all", "get");
      setOurBlog(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getTestimonialsData = async () => {
    try {
      const response = await axiosRequest("/api/testimonials/all", "get");
      setTestimonials(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getTrustedByData = async () => {
    try {
      const response = await axiosRequest("/api/trustedby/all", "get");
      setTrustedBy(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getProductData = async () => {
    try {
      const response = await axiosRequest("/api/products/all", "get");
      setProducts(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getServicesData = async () => {
    try {
      const response = await axiosRequest("/api/services/all", "get");
      setServices(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  console.log("products", products);

  useEffect(() => {
    getWorkData();
    getSubWorkData();
    getBlogData();
    getTestimonialsData();
    getTrustedByData();
    getProductData();
    getServicesData();
  }, []);

  console.log("ourwork", ourWork);

  useEffect(() => {
    if (ourWork?.length > 0) {
      ourWork.forEach((work) => {
        getSubWorkData(work.id);
      });
    }
  }, [ourWork]);

  const borderRadius = "15px";

  return (
    <div className="front-page">
      
      {/* Banner */}
      <div
        className="front-page-banner position-relative w-100"
        style={{ backgroundImage: `url(${banner_img})` }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-10 col-12 text-md-start text-sm-start text-center">
            <h1 className="mb-3" data-aos="fade-right">
              Driving digital success through cutting-edge solutions
            </h1>
            <p data-aos="fade-left">
              Your trusted partner for software innovation and transformation.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-md-start justify-content-sm-start justify-content-center  mt-5">
              <Link to="/getInTouch" className="me-3 text-decoration-none">
                <Button btnTxt="Get in touch" bgColor="#FAA700" />
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <CustomButton text="Explore" color="#fff" borderColor="#fff" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Our Services */}
      <div className="container frontPage-services my-md-5 my-4">
        <div className="text-center mb-3">
          <p className="text-secondary" data-aos="fade-right">
            Our Services
          </p>
          <h1 data-aos="fade-left">
            Transform your business with our expertise.
          </h1>
        </div>
        <div className="row justify-content-lg-between justify-content-between">
          {services?.slice(0, 3)?.map((service, index) => {
            return (
              <div
                className="services-item col-lg-4 col-md-6 col-12 d-flex flex-column"
                data-aos="flip-left"
                key={index}
              >
                <div className="img-container position-relative">
                  <img
                    src={
                      service?.homepage_img
                        ? `${process.env.REACT_APP_UPLOADS}/${service?.homepage_img}`
                        : defaultImg
                    }
                    className="w-100"
                    alt="..."
                  />
                </div>
                <div className="content d-flex flex-column text-center text-lg-start text-md-start">
                  <h5 className="fw-bold my-3">{service?.title}</h5>
                  <p className="card-text">
                    {service.content?.length > 85
                      ? `${service.content.slice(0, 85)}...`
                      : service.content}
                  </p>
                  <div className="d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-center">
                    <ExploreLink
                      to={service.page_url}
                      linkTxt="Explore page"
                      color="#000"
                      lineColor="#000"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Our References */}
      <div className="container my-md-5 my-4">
        <div className="row frontPage-or">
          <p className="text-center" data-aos="fade-right">
            Our references
          </p>
          <h1 className="text-center" data-aos="fade-left">
            Trusted by
          </h1>
          <div className="col-12 my-5">
            <Carousel
              responsive={responsiveR}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={true}
              // customLeftArrow={<CustomLeftArrow />}
              // customRightArrow={<CustomRightArrow />}
            >
              {trustedBy.map((trust) => (
                <div className="trustedby-item" key={trust.id}>
                  <div className="img-container">
                    <img
                      src={`${process.env.REACT_APP_UPLOADS}/${trust.img_url}`}
                      alt={trust.name}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      {/* Latest Technologies */}
      <div className="frontPage-lt my-md-5 my-4">
        <div className="container">
          <div className="row align-items-lg-center align-items-md-start align-items-center">
            <div className="col-lg-6 col-md-5 col-12 justify-content-center order-md-1 order-2 mt-md-0 mt-3">
              <div className="img-container position-relative w-100">
                <img
                  src={img4}
                  alt="..."
                  className="img-fluid"
                  data-aos="flip-left"
                />
                 <div className="marquee d-flex position-absolute">
                  <div className="logos d-flex flex-column align-items-center justify-content-between">
                    <div className="logo d-flex align-item-center justify-content-center">
                      <img src={logo1} alt="..." />
                    </div>
                    <div className="logo d-flex align-item-center justify-content-center">
                      <img src={logo2} alt="..." />
                    </div>
                    <div className="logo d-flex align-item-center justify-content-center">
                      <img src={logo3} alt="..." />
                    </div>
                    <div className="logo d-flex align-item-center justify-content-center">
                      <img src={logo4} alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 col-12 order-md-2 order-1">
              <h2 className="text-md-start text-center">
                Harnessing the latest technologies, trends, and strategies to...
              </h2>

              <div className="my-md-4 my-3">
                <div className="lt-item d-flex align-items-center mb-3">
                  <FaCircleCheck className="me-3" />
                  <p className="mb-0">
                    Save your time and money with efficient operations.
                  </p>
                </div>

                <div className="lt-item d-flex align-items-center mb-3">
                  <FaCircleCheck className="me-3" />
                  <p className="mb-0">
                    Increase your customer loyalty with user satisfaction.
                  </p>
                </div>

                <div className="lt-item d-flex align-items-center">
                  <FaCircleCheck className="me-3" />
                  <p className="mb-0">
                    Protect your business from cyber threats.
                  </p>
                </div>
              </div>
              <div className="my-md-0 my-3 d-flex justify-content-md-start justify-content-center">
                <Button
                  btnTxt="Learn more about the technology we use"
                  bgColor="#292929"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Customer Service */}

      <div className="frontPage-cs my-md-5 my-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-12">
              <h2 className="text-md-start text-center">
                Committed to providing excellent customer service
              </h2>

              <div className="mt-lg-5 mt-3">
                <div className="short-desc d-flex align-items-center mb-4">
                  <FiFeather className="me-3" />
                  <p className="mb-0">
                    A team of experienced professionals who can help your
                    business achieve its goals.
                  </p>
                </div>

                <div className="short-desc d-flex align-items-center mb-4">
                  <FiEye className="me-3" />
                  <p className="mb-0">
                    Scalable services to meet the needs of your business
                  </p>
                </div>

                <div className="short-desc d-flex align-items-center">
                  <FiSun className="me-3" />
                  <p className="mb-0">
                    Committed to building a strong partnership
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 text-center mt-lg-0 mt-md-0 mt-4">
              <div className="position-relative">
                <img
                  src={img5}
                  alt="..."
                  className="img-fluid"
                  data-aos="flip-right"
                />
                <div
                  className="small-card d-flex flex-column justify-content-center position-absolute"
                  data-aos="fade-up"
                >
                  <Doughnut data={data} options={options} className="mb-3" />
                  <div className="item d-flex align-items-center">
                    <LuDot style={{ color: "#0DBBFC" }} />
                    <div className="inner-item d-flex align-items-center">
                      <p className="mb-0">45%</p>
                      <span className="mx-1">-</span>
                      <p className="mb-0">Agile Development</p>
                    </div>
                  </div>

                  <div className="item d-flex align-items-center">
                    <LuDot style={{ color: "#69E6A6" }} />
                    <div className="inner-item d-flex align-items-center">
                      <p className="mb-0">25%</p>
                      <span className="mx-1">-</span>
                      <p className="mb-0">Communication</p>
                    </div>
                  </div>

                  <div className="item d-flex align-items-center">
                    <LuDot style={{ color: "#C4C4C4" }} />
                    <div className="inner-item d-flex align-items-center">
                      <p className="mb-0">25%</p>
                      <span className="mx-1">-</span>
                      <p className="mb-0">Maintenance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="frontPage-testimonials">
        <div className="container">
          <h1 className="text-md-start text-center" data-aos="fade-right">
            Testimonials
          </h1>
          <div className="row my-5">
            <div className="col-12">
              <Carousel
                responsive={responsiveT}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                arrows={true}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                afterChange={handleAfterChange}
                slidesToSlide={1}
              >
                {testimonials &&
                  testimonials?.map((testimonial, index) => (
                    <div
                      className={`testimonial-item ${
                        isActive(index) ? "active" : ""
                      }`}
                      key={testimonial.id}
                    >
                      <div className="inner-item text-md-start text-center">
                        <h6>"{testimonial.content}"</h6>
                        <div className="d-flex flex-md-row flex-sm-row flex-column align-items-center mt-3 justify-content-md-start justify-content-center">
                          <div className="img-container me-3 mb-md-0 mb-sm-0 mb-3">
                            <img
                              src={`${process.env.REACT_APP_UPLOADS}/${testimonial.img_url}`}
                              alt="..."
                            />
                          </div>
                          <div>
                            <h6 className="m-0 fw-bold">
                              {testimonial.full_name}
                            </h6>
                            <p className="m-0">{testimonial.occupation}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      {/* Products */}

      <div className="frontPage-products my-md-5 my-4">
        <div className="text-center mb-md-5 mb-3">
          <p className="text-secondary" data-aos="fade-right">
            Products
          </p>
          <h1 data-aos="fade-left">
            Transform your business with our products.
          </h1>
        </div>

        <div className="container">
          {products?.slice(0, 2)?.map((product, index) => (
            <div
              className="row my-4 align-items-center justify-content-center"
              key={index}
            >
              <div className="col-md-5 col-12">
                <div className="img-container">
                  <img
                    src={`${process.env.REACT_APP_UPLOADS}/${product?.homepage_img_url}`}
                    alt={product?.title || "Product Image"}
                    className="img-fluid"
                    data-aos="fade-right"
                  />
                </div>
              </div>
              <div className="col-md-5 col-12 my-md-0 my-4">
                <h5>{product?.title}</h5>
                <p dangerouslySetInnerHTML={{__html:   product?.content?.length > 100
                    ? `${product?.content.slice(0, 100)}...`
                    : product?.content}}>
               
                </p>
                <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                  <ExploreLink
                    to={product.page_url}
                    linkTxt="Explore page"
                    color="#000"
                    lineColor="#000"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Our Work */}

      <div className="container my-md-5 my-4">
        <div className="row frontPage-our-work py-4">
          <div className="col-12">
            <p className="text-center" data-aos="fade-right">Our Work</p>

            <div className="slider-container">
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={3000}
                showDots={true}
                arrows={false}
              >
                {ourWork && ourWork?.map((work) => (
                  <div
                    className="row slide align-items-center mb-5"
                    key={work.id}
                  >
                    <div className="col-lg-5 col-md-6 col-12 order-lg-1 order-md-1 order-2">
                      <div className="img-container">
                        <img
                          src={`${process.env.REACT_APP_UPLOADS}/${work.img_url}`}
                          alt={work.title}
                          className="slide-image w-100"
                          data-aos="flip-right"
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-12 order-lg-2 order-md-2 order-1">
                      <div className="slide-content text-lg-start text-md-start text-center">
                        <h2 className="fw-bold">{work.title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: work?.content }}/>
                        <div className="d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-center">
                          <ExploreLink
                            to={work.work_url + work?.id}
                            linkTxt="See more"
                            color="#fff"
                            lineColor="#fff"
                            newWindow={false}
                          />
                       
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="container frontPage-blog mt-lg-5 mt-5 mb-3">
        <div className="text-center my-4">
          <p className="text-secondary mb-2" data-aos="fade-right">
            Our Blog
          </p>
          <h1 data-aos="fade-left">Read some of our blogs</h1>
        </div>

        <div className="row">
          {ourBlog?.slice(0, blogsToShow)?.map((blog) => (
            <div
              key={blog.id}
              className="blogs-item col-lg-4 col-md-6 col-12 d-flex flex-column justify-content-between my-md-0 my-2"
            >
              <div className="img-container position-relative">
                <img
                  src={
                    blog?.img_url
                      ? `${process.env.REACT_APP_UPLOADS}/${blog.img_url}`
                      : defaultImg
                  }
                  className="blog-img w-100"
                  alt="Card"
                />
              </div>
              <div className="content d-flex flex-column justify-content-between text-center text-lg-start text-md-start p-md-4 p-3">
                <div className="d-flex align-items-center justify-content-center justify-content-lg-start justify-content-md-start">
                  <h6 className="mb-0 me-3 fw-bold">{blog.category}</h6>
                  <p className="mb-0">
                    {moment(blog.date).format("MMMM Do, YYYY")}
                  </p>
                </div>
                <h5 className="card-text py-3 mb-0">
                  {blog?.content?.length > 100
                    ? `${blog.content.slice(0, 100)}...`
                    : blog.content}
                </h5>
                <div className="person d-flex align-items-center justify-content-center justify-content-lg-start justify-content-md-start">
                  <img
                    src={`${process.env.REACT_APP_UPLOADS}/${blog.user_img}`}
                    alt="Author"
                    className="user-img me-3"
                  />
                  <p className="mb-0">{blog.user}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center mt-5">
            {blogsToShow < Math.min(maxBlogsToShow, ourBlog?.length) && (
              <CustomButton
                text="Load more"
                color="#000"
                borderColor="#000"
                isButton
                onClick={handleLoadMore}
              />
            )}
            {blogsToShow > initialBlogsToShow && (
              <CustomButton
                text="Load less"
                color="#000"
                borderColor="#000"
                isButton
                onClick={handleLoadLess}
              />
            )}
          </div>
        </div>
      </div>

      {/* Your Email */}
      <div className="container my-md-5 my-4">
        <YourEmail borderRadius={borderRadius} />
      </div>
    </div>
  );
};

const CustomLeftArrow = ({ onClick }) => (
  <button className="custom-arrow custom-arrow-left" onClick={onClick}>
    <HiArrowSmallLeft />
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button className="custom-arrow custom-arrow-right" onClick={onClick}>
    <HiArrowSmallRight />
  </button>
);

export default FrontPage;
