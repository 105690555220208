import React from 'react'

const CustomButton = ({text, color, borderColor, isButton, onClick}) => {

    const CustomBtn = isButton ? 'button' : 'div';

  return (
    <CustomBtn className='custom-btn' style={{color: color, borderColor: borderColor}} onClick={onClick}>
        {text}
    </CustomBtn>
  )
}

export default CustomButton