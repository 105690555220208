import React from 'react'
import { Link } from 'react-router-dom'
import { FiArrowRight } from "react-icons/fi";


const ExploreLink = ({to, linkTxt, color, lineColor, newWindow}) => {
  return (
   <>
   <Link to={to} className='explore-link hvr-icon-wobble-horizontal' style={{color: color}} target={newWindow ? "_blank" : "_self"}>
    {linkTxt}
    <FiArrowRight  className="ms-2"/>
    <div className="line position-absolute left-lg-0 left-md-50" style={{backgroundColor: lineColor}}></div>
   </Link>
   </>
  )
}

export default ExploreLink


