import React, { useEffect, useState } from "react";
import YourEmail from "../components/YourEmail";
import banner_img from "../assets/images/products-banner-img.svg";
import feather from "../assets/images/feather.svg";
import useAxios from "../utils/useAxios";

import all from "../assets/images/all.svg";
import web from "../assets/images/web.svg";
import desktop from "../assets/images/desktop.svg";
import mobile from "../assets/images/mobile.svg";
import CustomButton from "../components/CustomButton";
import { Link } from "react-router-dom";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const { axiosRequest } = useAxios();

  const getData = async () => {
    try {
      const response = await axiosRequest("/api/products/all", "get");
      setProducts(response?.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const tabs = [
    { name: "All", label: "All", icon: all },
    { name: "Web", label: "Web", icon: web },
    { name: "Desktop", label: "Desktop", icon: desktop },
    { name: "Mobile", label: "Mobile", icon: mobile },
  ];
  
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName.toLowerCase());
  };
  
  const filterProductsByDeviceType = () => {
    if (selectedTab === "all") {
      return products;
    }
    return products.filter((product) =>
      product?.device_type?.split(',').map(type => type.trim()).includes(selectedTab)
    );
  };
  
  const filteredProducts = filterProductsByDeviceType();

  return (
    <div className="products-page">
      {/* Products Banner */}
      <div className="banner">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-lg-start text-md-start text-sm-start text-center">
              <p className="fw-lighter pt-3" data-aos="fade-left">Products</p>
              <h1 data-aos="fade-right">Products made to elevate your business.</h1>
              <div className="mt-5">
              <Link to="/otherProducts" style={{ textDecoration: "none" }}>
                <CustomButton text="Explore Complex Products" color="#fff" borderColor="#fff" className="py-2" />
              </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <img src={banner_img} alt="..." className="img-fluid" data-aos="flip-left"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-3 my-md-4 my-lg-5">
        <div className="tabs d-flex flex-wrap justify-content-around mb-md-4 mb-0">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              className={`tab-item ${selectedTab === tab.name.toLowerCase() ? "active" : ""
                }`}
              onClick={() => handleTabClick(tab.name)}
            >
              <img
                src={tab.icon}
                alt={`${tab.name} icon`}
                className="tab-icon"
              />
              <p className="tab-label">{tab.label}</p>
            </div>
          ))}
        </div>

        {filteredProducts?.map((product, index) => {
          const isOddIndex = index % 2 === 0;
          return (
            <div
              key={index}
              className="row align-items-center justify-content-center py-md-5 py-3"
            >
              {isOddIndex ? (
                <>
                  <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 order-1 order-md-1 text-lg-start text-md-start text-center">
                    <h2 className="mb-md-4 mb-3">{product?.title}</h2>
                    <p className="mb-5" dangerouslySetInnerHTML={{ __html: product?.content }}></p>
                    {product?.short_content?.length > 0 &&
                      <div className="short-desc d-flex align-items-center">
                        <img src={feather} alt="..." className="me-3" />
                        <p className="mb-0">{product?.short_content}</p>
                      </div>}
                  </div>
                  <div className="col-lg-5 col-md-6 col-12 d-flex align-items-center mt-lg-0 mt-md-0 mt-4 order-2 order-md-2 justify-content-md-end justify-content-center">
                    <div
                      id={`carouselExampleIndicators-${index}`}
                      className="carousel slide carousel-fade"
                      data-bs-ride="carousel"
                      data-aos="zoom-in"
                    >
                      <div className="carousel-indicators">
                        {product?.product_images?.length > 1 &&
                          product.product_images.map((_, imgIndex) => (
                            <button
                              key={imgIndex}
                              type="button"
                              data-bs-target={`#carouselExampleIndicators-${index}`}
                              data-bs-slide-to={imgIndex}
                              className={imgIndex === 0 ? "active" : ""}
                              aria-current={imgIndex === 0 ? "true" : undefined}
                              aria-label={`Slide ${imgIndex + 1}`}
                            ></button>
                          ))}
                      </div>
                      <div className="carousel-inner">
                        {product?.product_images?.map((product_img, imgIndex) => (
                          <div
                            key={imgIndex}
                            className={`carousel-item w-100 h-100 ${imgIndex === 0 ? "active" : ""
                              }`}
                          >
                            <img
                              src={`${process.env.REACT_APP_UPLOADS}/${product_img?.img_url}`}
                              className="d-block w-100"
                              alt={`Img ${imgIndex}`}
                            />
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-5 col-md-6 col-12 d-flex align-items-center mt-lg-0 mt-md-0 mt-4 order-2 order-md-1 justify-content-md-start justify-content-center">
                    <div
                      id={`carouselExampleIndicators-${index}`}
                      className="carousel slide carousel-fade"
                      data-bs-ride="carousel"
                      data-aos="zoom-in"
                    >
                      <div className="carousel-indicators">
                        {product?.product_images?.length > 1 &&
                          product.product_images.map((_, imgIndex) => (
                            <button
                              key={imgIndex}
                              type="button"
                              data-bs-target={`#carouselExampleIndicators-${index}`}
                              data-bs-slide-to={imgIndex}
                              className={imgIndex === 0 ? "active" : ""}
                              aria-current={imgIndex === 0 ? "true" : undefined}
                              aria-label={`Slide ${imgIndex + 1}`}
                            ></button>
                          ))}
                      </div>
                      <div className="carousel-inner">
                        {product?.product_images?.map((product_img, imgIndex) => (
                          <div
                            key={imgIndex}
                            className={`carousel-item w-100 h-100 ${imgIndex === 0 ? "active" : ""
                              }`}
                          >
                            <img
                              src={`${process.env.REACT_APP_UPLOADS}/${product_img?.img_url}`}
                              className="d-block w-100"
                              alt={`Img ${imgIndex}`}
                            />
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 order-1 order-md-2 text-lg-start text-md-start text-center">
                    <h2 className="mb-md-4 mb-3">{product?.title}</h2>
                    <p className="mb-5" dangerouslySetInnerHTML={{ __html: product?.content }}></p>
                    {product?.short_content?.length > 0 &&
                    <div className="short-desc d-flex align-items-center">
                      <img src={feather} alt="..." className="me-3" />
                      <p className="mb-0">{product?.short_content}</p>
                    </div>}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      <div>
        <YourEmail />
      </div>
    </div>
  );
};

export default Products;
