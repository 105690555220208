import React from "react";
import YourEmail from "../components/YourEmail";
import PartWithImgAside from "../components/PartWithImgAside";
import TextWithImage from "../components/TextWithImage";

import bgImage from "../assets/images/support-banner-img.svg";
import img from "../assets/images/support-img.svg";

import logo1 from "../assets/images/framework-logo1.svg";
import logo2 from "../assets/images/framework-logo2.svg";
import logo3 from "../assets/images/framework-logo3.svg";

const SupportAndConsulting = () => {
  return (
    <div className="support-page">
      {/* Banner */}
      <PartWithImgAside
        bgColor="#E5E5E5"
        color="black"
        title="Support & Consulting"
        imageSrc={bgImage}
        subtitle="Services"
        contentPosition="justify-content-center"
        textPosition="text-center"
      />

      {/* Application Support */}
      <TextWithImage
        img={img}
        title="Application Support & Continuous Improvement:"
        description="Unlock the full potential of online retail with our E-Commerce
         solutions. We specialize in creating user-friendly, visually
         appealing, and secure online stores that attract customers and
         boost sales. Whether you are launching a new E-Commerce platform
         or seeking to revamp an existing one, our experts are here to
         guide you towards E-Commerce success."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={false}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* UX/UI */}
      <TextWithImage
        img={img}
        title="UX/UI:"
        description="Unlock the full potential of online retail with our E-Commerce
         solutions. We specialize in creating user-friendly, visually
         appealing, and secure online stores that attract customers and
         boost sales. Whether you are launching a new E-Commerce platform
         or seeking to revamp an existing one, our experts are here to
         guide you towards E-Commerce success."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={true}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* Testing & QA */}
      <TextWithImage
        img={img}
        title="Testing & QA:"
        description="Experience innovation in action with our Proof-of-Concept Design
          and MVP Development services. We turn your ideas into tangible
          solutions, validating their potential and gathering valuable user
          feedback for success. Let's create a remarkable product that
          stands out in the market and delights your audience."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={false}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* Project Management / Scrum */}
      <TextWithImage
        img={img}
        title="Project Management / Scrum:"
        description="Modernize your legacy systems and unlock their full potential with
          our Migration & Enhancement services. We assist in migrating your
          applications to modern platforms, ensuring improved performance
          and compatibility. Additionally, our team enhances legacy
          applications with new features and user-friendly interfaces,
          revitalizing their usability and extending their life cycle. Let's
          rejuvenate your legacy systems and pave the way for greater
          efficiency."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={true}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* Technical and Software Consultancy */}
      <TextWithImage
        img={img}
        title="Technical and Software Consultancy:"
        description="Modernize your legacy systems and unlock their full potential with
          our Migration & Enhancement services. We assist in migrating your
          applications to modern platforms, ensuring improved performance
          and compatibility. Additionally, our team enhances legacy
          applications with new features and user-friendly interfaces,
          revitalizing their usability and extending their life cycle. Let's
          rejuvenate your legacy systems and pave the way for greater
          efficiency."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={false}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* Deployment, Maintenance, and Support */}
      <TextWithImage
        img={img}
        title="Deployment, Maintenance, and Support:"
        description="Modernize your legacy systems and unlock their full potential with
          our Migration & Enhancement services. We assist in migrating your
          applications to modern platforms, ensuring improved performance
          and compatibility. Additionally, our team enhances legacy
          applications with new features and user-friendly interfaces,
          revitalizing their usability and extending their life cycle. Let's
          rejuvenate your legacy systems and pave the way for greater
          efficiency."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={true}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* Outsourcing */}
      <TextWithImage
        img={img}
        title="Outsourcing:"
        description="Optimize your resources and focus on core business activities by
          outsourcing specific tasks to us. Our dedicated teams ensure that
          outsourced projects are completed with efficiency and proficiency."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={false}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      {/* Code Review and Progress Reporting */}
      <TextWithImage
        img={img}
        title="Code Review and Progress Reporting:"
        description="Ensure the quality of your code with our thorough code review
          services. Our experts assess the source code to identify any
          vulnerabilities or areas for improvement. Additionally, we provide
          transparent progress reporting to keep you informed of project
          developments and ensure a smooth development process."
        link="/"
        linkColor="#000"
        linkLineColor="#000"
        reverseContentOrder={true}
        frameworkText="Mobile App Frameworks"
        frameworks={[logo1, logo2, logo3]}
        smallCard={[
          { color: "#0DBBFC", percentage: "45%", text: "Agile Development" },
          { color: "#69E6A6", percentage: "25%", text: "Communication" },
          { color: "#C4C4C4", percentage: "25%", text: "Maintenance" },
        ]}
      />

      <div>
        <YourEmail />
      </div>
    </div>
  );
};

export default SupportAndConsulting;
