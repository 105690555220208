import React, { useRef, useEffect } from "react";
import ExploreLink from "./ExploreLink";
import CustomBtn from "./CustomButton";

const PartWithImgAside = ({
  bgColor,
  color,
  subtitle,
  title,
  imageSrc,
  contentPosition,
  textPosition,
  containerType,
  content,
  exploreLink,
  to,
  linkTxt,
  linkColor,
  linkLineColor,
  customButtons,
  height,
  heightSm
}) => {

const sectionRef = useRef(null);
useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      sectionRef.current.style.height = heightSm || '41vh';
    } else {
      sectionRef.current.style.height = height || '70vh';
    }
  };

  handleResize();
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [height, heightSm]);
  return (
    
    <section
     ref={sectionRef}
      className={`partWithImgAside d-flex flex-column align-items-start ${
        contentPosition
          ? contentPosition
          : "justify-content-md-start justify-content-center"
      }`}
      style={{
        backgroundColor: bgColor,
        backgroundImage: `url(${imageSrc})`,
        height: height ? height : "70vh",
        // "--sm-height": heightSm ? heightSm : "41vh"

      }}
    >
      <div className={`${containerType ? containerType : "container"}`}>
        <p
          className={`${
            textPosition ? textPosition : "text-md-start text-center"
          }`}
          style={{ color: color }}
          data-aos="fade-left"
        >
          {subtitle}
        </p>

        <div className="row text-center justify-content-md-start justify-content-center">
          <h1
            className={`pb-md-5 pb-3 fw-lighter ${
              textPosition ? textPosition : "text-md-start text-center"
            }`}
            style={{ color: color }}
            data-aos="zoom-in"
          >
            {title}
          </h1>
          {content?.length > 0 ? (
            <div className="col-lg-10 col-11">
              <p className="text-md-start text-center" style={{ color: color }}>{content}</p>
              <div className="d-flex flex-column flex-wrap my-4 "  data-aos="fade-right">
                {customButtons &&
                  customButtons
                    .reduce((pairs, button, index) => {
                      if (index % 2 === 0) {
                        pairs.push([button]);
                      } else {
                        pairs[pairs.length - 1].push(button);
                      }
                      return pairs;
                    }, [])
                    .map((pair, index) => (
                      <div className="mb-md-2 mb-0" key={index}>
                        <div className="d-flex flex-lg-row flex-md-row flex-column align-items-center justify-content-md-start justify-content-center">
                          {pair.map((button, idx) => (
                            <div className="me-2 mb-lg-0 mb-md-0 mb-2 d-flex align-items-center justify-content-md-start justify-content-center" key={idx}>
                              <CustomBtn
                              // key={idx}
                              text={button.text}
                              color={button.color}
                              borderColor={button.borderColor}
                            />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
              </div>

              <div className="mb-5 d-flex align-items-center justify-content-md-start justify-content-center">

              {exploreLink && (
                <ExploreLink
                  to={to}
                  linkTxt={linkTxt}
                  color={linkColor}
                  lineColor={linkLineColor}
                />
              )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default PartWithImgAside;
