import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { RoutesWithLoader } from '../routes';
import { TailSpin } from "react-loader-spinner";
import Navbar from '../components/Navbar';
import Services from './Services';
import SoftwareDevelopment from './SoftwareDevelopment';
import BusinessSolutions from './BusinessSolutions';
import SupportAndConsulting from './SupportAndConsulting';
import Products from './Products';
import GetInTouch from './GetInTouch';
import Apply from './Apply';
import NotFound from './NotFound';
import ServerError from './ServerError';
import FrontPage from './FrontPage';
import { ColorThemeProvider } from "./ColorThemeContext"
import Footer from '../components/Footer';

import ScrollToTop from "../ScrollToTop"
import SingleService from './singleService';
import SingleWork from './singleWork';

import Aos from "aos";
import "aos/dist/aos.css";
import Technology from './technology';
import ComplexProducts from './ComplexProducts';

const About = React.lazy(() => import('./About'));
const RouteWithLoader = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading ?
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <TailSpin color="#FAA700" radius="8px" />
                </div>
                :
                children
            }
        </>
    );
};

const Homepage = () => {
    const location = useLocation();
    const another = "#FAA700"
    const blackColor = "#292929"
    const greyColor = "#E5E5E5"
    let initialColorTheme;
    console.log("location", location.pathname ,initialColorTheme)
    if (location.pathname === '/about' || '/getIntouch') {
        initialColorTheme = blackColor;
    } if (location.pathname === '/apply' || '/otherProducts') {
        initialColorTheme = greyColor;
    }

    useEffect(() => {
        Aos.init({
            duration: 1250,
            once: true,
        });
    }, []);
    
    return (
        <RouteWithLoader>
          <ColorThemeProvider initialColorTheme={initialColorTheme}>
            <Navbar />
            <ScrollToTop />
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path={RoutesWithLoader.FrontPage.path} element={<FrontPage />} />
                    <Route path={RoutesWithLoader.About.path} element={<About />} />
                    <Route path={RoutesWithLoader.Services.path} element={<Services />} />
                    <Route path={RoutesWithLoader.SingleService.path} element={<SingleService />} />
                    {/* <Route path="/services/:id" element={<SingleService />} /> */}
                    <Route path='/:id' element={<SingleWork />}/>
                    <Route path={RoutesWithLoader.SoftwareDevelopment.path} element={<SoftwareDevelopment />} />
                    <Route path={RoutesWithLoader.BusinessSolutions.path} element={<BusinessSolutions />} />
                    <Route path={RoutesWithLoader.SupportAndConsulting.path} element={<SupportAndConsulting />} />
                    <Route path={RoutesWithLoader.Products.path} element={<Products />} />
                    <Route path={RoutesWithLoader.Technology.path} element={<Technology />} />
                    <Route path={RoutesWithLoader.ComplexProducts.path} element={<ComplexProducts />} />
                    <Route path={RoutesWithLoader.GetInTouch.path} element={<GetInTouch />} />
                    <Route path={RoutesWithLoader.Apply.path} element={<Apply />} />
                    <Route path={RoutesWithLoader.NotFound.path} element={<NotFound />} />
                    <Route path={RoutesWithLoader.ServerError.path} element={<ServerError />} />
                </Routes>
            </Suspense>
            </ColorThemeProvider>
            <Footer />
            {/* <Redirect to={Routes.NotFound.path} /> */}
        </RouteWithLoader>
    );
};

export default Homepage;
